

<template>
	<div class="tcontainer">
		<div class="clearfix ttitle">
			<div class="title">
				<div class="text-danger pull-right" style="margin-right: 5px">Due: &#8377;{{ pulmonologist?.due_amount ?
					pulmonologist.due_amount : 0 }}</div>
				Make Payment to {{ pulmonologist?.name }}
			</div>
		</div>
		<div style="padding: 30px 0; background: #fff; margin-bottom: 10px; border: 1px solid #eee; border-top: 0;">
			<div class="container">
				<div class="row">
					<div class="col-sm-2"></div>
					<div class="col-sm-8" style="display: flex">
						<input v-model="pay.amount" style="margin-left: 5px" type="number" class="form-control"
							placeholder="Enter amount to pay">
						<select v-model="pay.mode" style="margin-left: 5px" class="form-control">
							<option value="" disabled>Select Payemnt Mode</option>
							<option>NEFT</option>
							<option>Cash</option>
							<option>Paytm</option>
							<option>GPay</option>
							<option>UPI</option>
						</select>
						<input v-model="pay.transaction_id" style="margin-left: 5px" type="text" class="form-control"
							placeholder="Transaction Id">
						<button @click="makePayment" style="margin-left: 5px" type="button" class="btn btn-primary"
							:disabled="!Number(pay.amount) || !pay.mode || !pay.transaction_id">Pay</button>
					</div>
					<div class="col-sm-2"></div>
				</div>
			</div>
		</div>

		<div class="clearfix ttitle">
			<div class="title">Manage Pulmonologist Payment History</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column field="id" header="Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id'] }}
					</div>
				</template>
			</Column>
			<Column field="amount" header="Amount" filterField="amount" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Amount" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['amount'] }}
					</div>
				</template>
			</Column>
			<Column field="mode" header="Mode" filterField="mode" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Mode" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['mode'] }}
					</div>
				</template>
			</Column>
			<Column field="transaction_id" header="Transaction Id" filterField="transaction_id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Transaction Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['transaction_id'] }}
					</div>
				</template>
			</Column>
			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" style="width: 65px; margin: auto;">
						{{ format(data['created_at'], "DD/MM/YY h:mmA") }}
					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" ref="deleteModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-danger text-white">
						<h5 class="modal-title">Delete Confirmation</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">
						<div class="text-danger" style="font-weight: bold; text-align: left; margin-bottom: 8px;">Record ID:
							{{ editId }}</div>
						You are about to delete this record, Are you sure?
						<br>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" @click="deleteRecord">Delete</button>
					</div>
				</div>
			</div>
		</div>

		<form action="" @submit.prevent="putRecord">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								<div v-if="editId == 0">Add Center</div>
								<div v-else>Edit Center</div>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">

							<div class="form-group ui-float-label">
								<input type="number" class="form-control"
									:class="{ 'is-invalid': fcontrol.doctor_id?.hasError && fcontrol.doctor_id.touched }"
									v-model="fdata.doctor_id" @blur="fcontrol.doctor_id.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.doctor_id?.hasError">
									<span v-if="fcontrol.doctor_id.errors.required">You must enter doctor id</span>
								</div>
								<label>Doctor Id</label>
							</div>
							<div class="form-group ui-float-label">
								<input type="number" class="form-control"
									:class="{ 'is-invalid': fcontrol.amount?.hasError && fcontrol.amount.touched }"
									v-model="fdata.amount" @blur="fcontrol.amount.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.amount?.hasError">
									<span v-if="fcontrol.amount.errors.required">You must enter amount</span>
								</div>
								<label>Amount</label>
							</div>
							<div class="form-group ui-float-label">
								<input type="text" class="form-control"
									:class="{ 'is-invalid': fcontrol.mode?.hasError && fcontrol.mode.touched }" v-model="fdata.mode"
									@blur="fcontrol.mode.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.mode?.hasError">
									<span v-if="fcontrol.mode.errors.required">You must enter mode</span>
									<span v-if="fcontrol.mode.errors.max">Mode can contain maximum
										{{ fcontrol.mode.errors.max.maxLength }} characters</span>
								</div>
								<label>Mode</label>
							</div>
							<div class="form-group ui-float-label">
								<input type="text" class="form-control"
									:class="{ 'is-invalid': fcontrol.transaction_id?.hasError && fcontrol.transaction_id.touched }"
									v-model="fdata.transaction_id" @blur="fcontrol.transaction_id.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.transaction_id?.hasError">
									<span v-if="fcontrol.transaction_id.errors.required">You must enter transaction id</span>
									<span v-if="fcontrol.transaction_id.errors.max">Transaction Id can contain maximum
										{{ fcontrol.transaction_id.errors.max.maxLength }} characters</span>
								</div>
								<label>Transaction Id</label>
							</div>
							<div class="form-group ui-float-label">
								<input type="text" class="form-control"
									:class="{ 'is-invalid': fcontrol.created_at?.hasError && fcontrol.created_at.touched }"
									v-model="fdata.created_at" @blur="fcontrol.created_at.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.created_at?.hasError">
									<span v-if="fcontrol.created_at.errors.required">You must enter created at</span>
								</div>
								<label>Created At</label>
							</div>

						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>

	</div>
</template>

<script>
import { Modal } from 'bootstrap'
export default {
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			saving: false,
			addModal: null,
			deleteModal: null,
			lazyParams: {},
			pulmonologist: null,
			pay: {
				doctor_id: this.$route.params.id,
				amount: '',
				mode: '',
				transaction_id: ''
			},
			filters: {
				'id': { value: '', matchMode: 'like' },
				'doctor_id': { value: '', matchMode: 'like' },
				'amount': { value: '', matchMode: 'like' },
				'mode': { value: '', matchMode: 'like' },
				'transaction_id': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
			},
			fdata: {
				id: '',
				doctor_id: '',
				amount: '',
				mode: '',
				transaction_id: '',
				created_at: '',
			},
			fvalidator: {
				doctor_id: 'required',
				amount: 'required',
				mode: 'required|max:255',
				transaction_id: 'required|max:255',
				created_at: 'required',
			}
		}
	},
	mounted() {
		this.addModal = new Modal(this.$refs.addModal);
		this.deleteModal = new Modal(this.$refs.deleteModal);

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();
		this.loadPulmonologist();

	},
	methods: {
		loadPulmonologist() {
			this.post("admin/load-pulmonologists", {
				first: 0,
				rows: 1,
				sortField: "id",
				sortOrder: -1,
				filters: {
					id: {
						matchMode: '=',
						value: this.$route.params.id,
					}
				}
			}).then(res => {
				this.loading = false;
				if (res.success) {
					this.pulmonologist = res.rows[0];
				}
			});
		},
		makePayment() {
			this.post("admin/make-pulmonologist-payment", this.pay).then(res => {
				if (res.success) {
					this.pay.amount = ''
					this.pay.mode = '';
					this.pay.transaction_id = '';
					this.loadLazyData();
					this.loadPulmonologist();
				} else {
					alert("Payment failed")
				}
			});
		},
		putRecord() {
			if (this.fHasError() || this.saving) return;
			this.saving = true;


			if (this.editId) this.fdata.id = this.editId;
			this.post("admin/put-pulmonologist_payment_history", this.fdata).then(res => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
				}
			});
		},
		searchDoctor(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredDoctors = [...this.customers];
				} else {
					this.filteredDoctors = this.customers.filter((country) => {
						return country.name.toLowerCase().startsWith(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		showAddEdit(row) {
			this.freset();
			this.editId = row ? row.id : 0;
			if (row) this.fpopulate(row);
			this.addModal.show();
		},
		deleteRecord() {
			this.delete("admin/delete-pulmonologist_payment_history/" + this.editId).then(res => {
				this.saving = false;
				if (res.success) {
					this.deleteModal.hide();
					this.loadLazyData();
				}
			});
		},
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));

			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			params.filters['doctor_id'] = {
				value: this.$route.params.id,
				matchMode: '='
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}

			this.post("admin/load-pulmonologist_payment_history", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped></style>